import React, { createContext, useContext, useMemo, useState } from "react";
import dynamic from "next/dynamic";

import { ActionButtonProps } from "@bxtech/bx-ui-lib/components/Modal";

const DynamicConfirmationModal = dynamic(
  () => import("@bxtech/bx-ui-lib/components/Modal"),
  {
    ssr: false,
  }
);

export type OpenConfirmationModal = (
  title: string,
  message: string | React.ReactNode,
  buttonProps: ActionButtonProps,
  content?: React.ReactNode,
  secondaryButtonLabel?: string
) => void;

interface ConfirmationModalContextType {
  isOpen: boolean;
  title: string;
  message: React.ReactNode;
  buttonProps: ActionButtonProps;
  openConfirmationModal: OpenConfirmationModal;
  closeConfirmationModal: () => void;
  content: React.ReactNode;
}

interface ConfirmationModalProviderProps {
  children: React.ReactNode;
}

export const ConfirmationModalContext =
  createContext<ConfirmationModalContextType>({
    isOpen: false,
    title: "",
    message: "",
    buttonProps: {
      label: "Confirm",
      onClick: () => {},
    },
    openConfirmationModal: () => {},
    closeConfirmationModal: () => {},
    content: <div />,
  });

export const useConfirmationModal = () => {
  const context = useContext(ConfirmationModalContext);
  if (!context) {
    throw new Error("useConfirmationModal must be used within ModalProvider");
  }
  return context;
};

export const ConfirmationModalProvider = ({
  children,
}: ConfirmationModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState<React.ReactNode>("");
  const [buttonProps, setButtonProps] = useState<ActionButtonProps>({
    label: "Confirm",
    onClick: () => {},
  });
  const [content, setContent] = useState<React.ReactNode>(<div />);
  const [secondaryButtonLabel, setSecondaryButtonLabel] =
    useState<string | undefined>();

  const openConfirmationModal = (
    newTitle?: string,
    newMessage?: string | React.ReactNode,
    newButtonProps?: ActionButtonProps,
    newContent?: React.ReactNode,
    newSecondaryButtonLabel?: string
  ) => {
    const wrappedOnClick = () => {
      setIsOpen(false);
      if (newButtonProps?.onClick) {
        newButtonProps.onClick();
      }
    };

    setTitle(newTitle!);
    setMessage(newMessage!);
    setButtonProps({
      ...newButtonProps!,
      onClick: wrappedOnClick,
    });
    setContent(newContent!);
    setSecondaryButtonLabel(newSecondaryButtonLabel);
    setIsOpen(true);
  };

  const closeConfirmationModal = () => {
    setSecondaryButtonLabel(undefined);
    setIsOpen(false);
  };

  const value = useMemo(
    () => ({
      isOpen,
      title,
      message,
      buttonProps,
      openConfirmationModal,
      closeConfirmationModal,
      content,
    }),
    [isOpen, title, message]
  );

  buttonProps.onClick.bind(closeConfirmationModal);

  return (
    <ConfirmationModalContext.Provider value={value}>
      <DynamicConfirmationModal
        open={isOpen}
        title={title}
        titleBlockBody={message}
        handleClose={closeConfirmationModal}
        handleBack={closeConfirmationModal}
        footer={{
          primaryButton: buttonProps,
          secondaryButton: {
            label: secondaryButtonLabel || "Go back",
            onClick: closeConfirmationModal,
          },
          contactBXLabel: "",
          contactBXLink: "",
        }}
        variant="small"
        content={content}
      />
      {children}
    </ConfirmationModalContext.Provider>
  );
};
